@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
/* Autofill Styles */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s; /* Consider adjusting the transition duration */
  background-color: #fff;
  border-radius: 4px;
  [data-theme="dark"] & {
    background-color: #333;
  }
}

/* Scrollbar Styles */
::-webkit-scrollbar {
  width: 12px;
  border-radius: 4px;
}

::-webkit-scrollbar-track {
  background-color: #c9c2c2;
  border-radius: 4px;
  [data-theme="dark"] & {
    background-color: #333;
  }
}

::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 4px;
  [data-theme="dark"] & {
    background-color: #b6a8a8;
  }
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555;
  [data-theme="dark"] & {
    background-color: #8a8080;
  }
}
.scrollforchat::-webkit-scrollbar {
  width: 6px;
  border-radius: 4px;
}

.scrollforchat::-webkit-scrollbar-track {
  background-color: #c9c2c2;
  [data-theme="dark"] & {
    background-color: #333;
  }
}

.scrollforchat::-webkit-scrollbar-thumb {
  background-color: #252121;
  border-radius: 4px;
  [data-theme="dark"] & {
    background-color: #8a8080;
  }
}

.scrollforchat::-webkit-scrollbar-thumb:hover {
  background-color: #555;
  [data-theme="dark"] & {
    background-color: #b6a8a8;
  }
}

@media only screen and (max-width: 768px) {
  /* Hide scrollbar on small screens */
  .scrollforchat::-webkit-scrollbar {
    display: none;
  }
}

[data-theme="dark"] {
  @apply bg-[#4c4c4c] text-[#e3ebdb];
}
[data-theme="light"] {
  @apply bg-[#f5f4f069] text-black;
}
.forum-btn1 {
  @apply text-white px-4 py-1 rounded-lg cursor-pointer;
}
.forum-btn-sm2 {
  @apply text-white px-2 py-1 rounded-lg cursor-pointer font-semibold text-xs;
}

.btn-red {
  padding: 4px ;
  background: #ff416c; /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #ff4b2b, #ff416c);
  background: linear-gradient(to right, #ff4b2b, #ff416c);
  border-radius: 8px;
  color: white;
  cursor: pointer;
}
.btn-blue {
  padding: 6px 10px;
  color: white;
  cursor: pointer;
  background-image: linear-gradient(to top, #4481eb 0%, #04befe 100%);
  border-radius: 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}

.nav-bg {
  /* background-image: linear-gradient(
    to top,
    #a7a6cb 0%,
    #8989ba 52%,
    #8989ba 100%
  ); */
box-shadow: inset 0 -5px 20px -15px currentColor;  
  /* [data-theme="light"] & {
    background-image: linear-gradient(to top, #d5d4d0 0%, #d5d4d0 1%, #eeeeec 31%, #efeeec 75%, #e9e9e7 100%);
  }

  [data-theme="dark"] & {
    background-image: linear-gradient(to right, #868f96 0%, #596164 100%);
   } */
}
.forum-btn2 {
  @apply lg:hover:text-white duration-300 px-4 py-1 rounded-lg cursor-pointer w-full;
}
.cardinhome {
  @apply lg:w-[50vw] lg:max-w-[800px] md:w-[90vw] md:max-w-[500px] w-[95vw] mx-auto;
}
.forum-btn-sm {
  @apply px-2 py-1 rounded-md text-sm;
}
.w-calc {
  width: calc(50% - 2px);
}

.swiper-pagination-bullet {
  color: white !important;
  mix-blend-mode: difference !important;
}
.statsHeaderTitle {
  @apply text-center font-semibold text-xl;
}
.width-full {
  width: 100% !important;
}
.highlightedClass {
  background-color: #888 !important;
}
.online-border-color {
  border-width: 2px;
  animation: runningColor 6s cubic-bezier(0.4, 0, 0.2, 1) infinite;
}
.offline-border-color {
  border-color: gray;
  border-width: 2px;
}
.online-color {
  background-color: #27c93f;
}
.online-text-color {
  color: #27c93f;
  font-size: 10px;
  font-weight: 400;
  display: inline;
}
.stats-card {
  @apply bg-[#fffef9] shadow-xl dark:bg-[#242526] cardinhome py-4 min-h-[200px];
}
.ash-bg{
  background-image: linear-gradient(60deg, #29323c 0%, #485563 100%);
}
/* green btn */
.btn-green {
  color: white;
  min-width: 100px;
  max-width: unset;
  margin: 20px 0px;
  padding: 5px;
  border-radius: 8px;
}
.btn-green-sm {
  color: white;
  min-width: 100px;
  max-width: unset;
  padding: 5px;
  background-image: linear-gradient(to top, #0ba360 0%, #3cba92 100%);
  cursor: pointer;
  border-radius: 8px;
  text-align: center;
}
.reaction-item{
  @apply lg:hover:dark:bg-[#4B4C4F] lg:hover:bg-slate-200 md:px-[10px] rounded-md md:min-w-[100px] gap-[14px] flex items-center justify-center
}
.reaction-box{
  @apply flex items-center justify-around py-[2px] mx-auto w-[98%] border-t-[0.5px] border-b-[0.5px] border-black dark:border-gray-500 border-opacity-20
}

.btn-green-active {
  background-image: linear-gradient(to top, #0ba360 0%, #3cba92 100%);
  cursor: pointer;
}
.btn-green-active:hover {
  background-image: linear-gradient(to top, #0ba360 0%, #3cba92 20%);
}
.btn-green-disabled {
  color: black;
  background-color: #dcd9d4;
  background-image: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.5) 0%,
      rgba(0, 0, 0, 0.5) 100%
    ),
    radial-gradient(
      at 50% 0%,
      rgba(255, 255, 255, 0.1) 0%,
      rgba(0, 0, 0, 0.5) 50%
    );
  background-blend-mode: soft-light, screen;
}

.scroll-reveal {
  white-space: pre-wrap;
  overflow-wrap: break-word;
  display: inline;
  background-clip: text;
  background-repeat: no-repeat;
  background-size: 0% 100%;
  animation: scroll-reveal linear forwards;
  color: hsla(0, 12%, 97%, 0.5);
  animation-timeline: view();
  animation-range: cover;
  animation-range-start: 5vh;
  animation-range-end: 20vh;
}
.scroll-reveal {
  [data-theme="light"] & {
    background-image: linear-gradient(90deg, black, black);
    color: hsla(0, 0%, 1%, 0.5);
  }
  [data-theme="dark"] & {
    background-image: linear-gradient(90deg, white, white);
    color: hsla(0, 0%, 100%, 0.5);
  }
}

@keyframes scroll-reveal {
  from {
    background-size: 0% 100%;
  }
  to {
    background-size: 100% 100%;
  }
}
@keyframes runningColor {
  0% {
    border-color: #00ff00;
  }
  16.666% {
    border-color: #33ff33; 
  }
  33.333% {
    border-color: #66ff66; 
  }
  50% {
    border-color: #99ff99;
  }
  66.666% {
    border-color: #ccffcc; 
  }
  83.333% {
    border-color: #7637e7; 
  }
  100% {
    border-color: #00ff00; 
  }
}